<template>
  <div class="mx-4 md:mx-0" :class="isMobileOrTablet ? 'sm:mx-24' : ' sm:mx-0'">
    <div
      class="mx-auto md:mt-52"
      :class="blok.fullscreen ? 'w-full' : 'max-w-1400'"
      :style="{
        backgroundColor: blok.backgroundColor?.color
          ? blok.backgroundColor?.color
          : '#E8F5E9',
      }"
    >
      <div
        class="flex flex-col gap-y-4 md:flex-row md:gap-x-4 md:mx-2 xl:gap-x-12 lg:max-w-1400 lg:justify-center lg:mx-auto"
      >
        <img
          :src="OptimizeStoryBlokImage(blok.image?.filename)"
          class="w-full md:w-500 xl:w-600 max-h-290 xl:max-h-450 pt-8 lg:pt-0 px-4 md:-mt-24 xl:-mt-28"
          :class="left ? 'pt-8 md:pb-8 order-first' : 'pb-8 order-last'"
        />
        <div
          class="px-4 space-y-4 md:pb-8 md:pt-8"
          :class="right ? 'pt-8' : 'pb-8'"
        >
          <h2
            class="font-bodoni text-xl md:text-3xl lg:text-34 font-bold tracking-wide leading-10 text-sanremo-black xl:max-w-xl"
          >
            {{ blok.title }}
          </h2>

          <p
            class="text-sanremo-black text-xs xl:text-sm leading-7 xl:leading-7 xl:max-w-lg"
          >
            {{ blok.text }}
          </p>

          <div>
            <NuxtLink
              :to="blok?.url?.url ? blok.url.url : `/${locale}`"
              class="underline-offset-8 text-10 xl:text-sm font-bold tracking-wider uppercase underline text-sanremo-black"
            >
              {{ blok.urltext }}
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { OptimizeStoryBlokImage } from '@/utils/helpers'
const props = defineProps({
  blok: Object,
})

const { locale } = useI18n()

const { isMobileOrTablet } = useDevice()

const left = computed(() => {
  return props.blok.positionimage === 'left'
})

const right = computed(() => {
  return props.blok.positionimage === 'right'
})
</script>
